import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"flat":"","value":0}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('Customer'))+" ")]),_c(VExpansionPanelContent,[_c('div',{staticClass:"d-flex flex-column"},[(_vm.value.customer.customer_id)?_c('router-link',{attrs:{"to":{
            name: 'customer-update',
            params: { id: _vm.value.customer.customer_id }
          }}},[_vm._v(" "+_vm._s(((_vm.value.customer.firstname) + " " + (_vm.value.customer.lastname)))+" ")]):_c('span',[_vm._v(" "+_vm._s(((_vm.value.customer.firstname) + " " + (_vm.value.customer.lastname)))+" ")]),_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.value.customer.email))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }