import { ProductComponentPriceType } from '@futureecom/futureecom-js/dist/services/catalog-service'
import { isBundle, isConfigurable } from '@futureecom/futureecom-js/dist/helpers/catalog/product-helpers'
import Big from 'big.js'
import type { BaseVat } from '@futureecom/futureecom-js/dist/services/tax-service'
import type { Money } from '@futureecom/futureecom-js'
import type {
  Orderable,
  OrderableCheckout,
  OrderableCustomerDetails,
  OrderableGuestDetails,
  OrderableItemComponentData,
  OrderableItemData
} from '@futureecom/futureecom-js/dist/services/cart-service'
import type { Product, ProductBundle, ProductComponent } from '@futureecom/futureecom-js/dist/services/catalog-service'

export const hasAddress = (cart: Partial<Orderable>, addressType: 'shipping_address' | 'billing_address') => {
  return !!cart[addressType] && !Array.isArray(cart[addressType])
}

export const isGuest = (val: Orderable['customer']): val is OrderableGuestDetails => 'guest_id' in val
export const isCustomer = (val: Orderable['customer']): val is OrderableCustomerDetails => 'customer_id' in val

export const getCheckoutCustomer = (item: Partial<Orderable>): OrderableCheckout['customer'] => {
  if (!item.customer) {
    return undefined
  }

  const { firstname, lastname, email, dob } = item.customer
  return firstname && lastname && email ? { firstname, lastname, email, dob: dob || undefined } : undefined
}

export const findProductChild = (product: Product, id: string): Product | undefined => {
  if (isConfigurable(product)) {
    return product.children.find((child) => child.id === id)
  }
  return undefined
}

export const getComponentPriceAmount = (component: ProductComponent, id: string, vat?: BaseVat['value']): string => {
  return {
    [ProductComponentPriceType.NONE]: (): string => '',
    [ProductComponentPriceType.DYNAMIC]: (): string => {
      return Big((findProductChild(component.product, id) || component.product)?.price.amount || 0)
        .mul((vat || 0) + 1)
        .toString()
    },
    [ProductComponentPriceType.FIXED]: (): string => `${(component.gross_price || component.price)?.amount || 0}`
  }[component.price_type]()
}

const calculateTotalBundle = (item: OrderableItemComponentData[], product: ProductBundle): Money => {
  const { gross_sale_price, gross_price, sale_price, price, vat } = product
  const { amount, currency } = gross_sale_price || gross_price || sale_price || price

  const componentsAmount: Big = item.reduce((prev, current) => {
    const component = product.components.find((component) => {
      return findProductChild(component.product, current.product_id) || component.product.id === current.product_id
    })

    if (!component) {
      return prev
    }

    const price = getComponentPriceAmount(component, current.product_id, vat?.value)
    return prev.add(Big(price || 0).mul(current.quantity || 1))
  }, new Big(amount))

  return { amount: componentsAmount.toString(), currency }
}

export const getOrderableProductPrice = (item: OrderableItemData, product: Product): Money => {
  const filteredComponents = (item.components || []).filter((item) => !item.exclude && !!item.product_id)

  return isBundle(product)
    ? calculateTotalBundle(filteredComponents, product)
    : (findProductChild(product, item.product_id) || product).real_price
}
