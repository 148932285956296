import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Quotes')))])]),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c('div',{staticClass:"px-4"},[_c('resource-grid',{attrs:{"options":_vm.options,"items":_vm.grid.data,"hide-default-footer":""},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessQuote(item).value ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'quote-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.getShortId(item.id))+" ")])]}},{key:"item.customer.firstname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer && item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{
              name: 'customer-update',
              params: { id: item.customer.customer_id }
            }}},[_vm._v(" "+_vm._s(item.customer.firstname)+" ")])]}},{key:"item.customer.lastname",fn:function(ref){
            var item = ref.item;
return [_c(_vm.canAccessCustomer && item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{
              name: 'customer-update',
              params: { id: item.customer.customer_id }
            }}},[_vm._v(" "+_vm._s(item.customer.lastname)+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('quote-status-chip',{attrs:{"value":item.status}})]}},{key:"item.summary.total_due.amount",fn:function(ref){
            var item = ref.item;
return [(item.summary.total_due)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.summary.total_due)))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.edit",fn:function(ref){
            var item = ref.item;
return [(_vm.canAccessQuote(item).value)?_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'quote-update', params: { id: item.id } }}}):_vm._e()]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('quote-action-delete',{attrs:{"value":item,"disabled":_vm.grid.loading,"icon":""},on:{"onSuccess":_vm.onDeleteSuccess}})]}}],null,true),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c(VDivider),(!heightExceeded)?_c('div',{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()],1)]}},{key:"sticky-bottom",fn:function(ref){
            var heightExceeded = ref.heightExceeded;
return [_c(VDivider),(heightExceeded)?_c(VFooter,{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }