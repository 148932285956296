import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("activator",null,null,{ attrs: attrs, on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.onApply.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[(!_vm.shipping)?_c('span',[_vm._v(_vm._s(_vm.$t('Set custom shipping')))]):_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(("" + (_vm.$t('Set custom shipping price'))))+" ")]),_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" "+_vm._s(("" + (_vm.shipping.name)))+" ")])]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isOpen = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VDivider),_c(VCardText,[_c('orderable-custom-shipping-inputs',{attrs:{"errors":_vm.errors},on:{"input":_vm.onItemChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"ma-2",attrs:{"depressed":"","color":"primary","type":"submit","disabled":!_vm.isValid,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }