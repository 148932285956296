import { VBtn } from 'vuetify/lib/components/VBtn';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canCreate)?_c('div',{staticClass:"d-flex justify-end"},[_c('customer-address-create',{attrs:{"value":_vm.value},on:{"created":_vm.onAddressCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add new address'))+" ")])]}}],null,false,116268071)})],1):_vm._e(),_vm._l((_vm.visibleAddresses),function(address){return _c('selectable-card',{key:address.id,class:{ 'v-card__active': _vm.selectedAddressId === address.id },attrs:{"loading":_vm.loading && _vm.selectedId === address.id},on:{"select":function($event){return _vm.onSelect(address.id)}}},[_c('div',{staticClass:"d-flex justify-space-between align-start flex-gap-32"},[_c('full-address-preview',{attrs:{"value":address}}),_c('div',{staticClass:"d-flex align-center flex-gap-16"},[_c('customer-address-update',{attrs:{"value":address,"customer":_vm.value},on:{"updated":_vm.onAddressUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.canUpdate)?_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on)):_vm._e()]}}],null,true)}),(_vm.canDelete && !address.is_default)?_c('address-action-delete',{attrs:{"value":address.id,"customer":_vm.value,"icon":""},on:{"success":function($event){return _vm.onAddressDelete(address.id)}}}):_vm._e()],1)],1)])}),(_vm.addressesPageCount > 1)?_c(VPagination,{staticClass:"elevation-0",attrs:{"length":_vm.addressesPageCount,"total-visible":5},model:{value:(_vm.currentAddressPage),callback:function ($$v) {_vm.currentAddressPage=$$v},expression:"currentAddressPage"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }