import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expandable-content',{attrs:{"width":0,"stripe-width":0,"value":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-gap-8 align-center px-4"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('Products'))+" ")]),(_vm.cart.items.find(function (item) { return item.is_custom; }) && !_vm.readonly)?_c('orderable-action-product-reset-price',{attrs:{"icon":"","small":"","icon-name":"restart_alt","label":_vm.$t('Reset all products prices and VATs')},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}}):_vm._e()],1)]},proxy:true},{key:"expand-icon",fn:function(ref){
var expanded = ref.expanded;
return [_c(VIcon,{staticClass:"mx-4"},[_vm._v(" "+_vm._s(expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down')+" ")])]}},{key:"content",fn:function(){return [_c('div',_vm._l((_vm.cart.items || []),function(item,index){return _c('div',{key:item.id,staticClass:"d-flex flex-column py-4"},[(index)?_c(VDivider):_vm._e(),_c('expandable-content',{attrs:{"stripe-width":0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-gap-16 px-4"},[(_vm.canDelete && !_vm.readonly)?_c('orderable-action-delete-item',{attrs:{"small":"","icon":"","item-id":item.id,"label":_vm.$t('Remove product')},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}}):_vm._e(),_c('orderable-preview-item',{staticClass:"d-flex flex-gap-16 flex-wrap",attrs:{"value":item,"quantity":item.quantity},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('product-price-preview',{attrs:{"quantity":item.quantity,"price":item.real_price,"subtotal":item.new_subtotal || item.subtotal}}),(item.is_custom)?_c('span',{staticClass:"d-flex flex-gap-8 align-center"},[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.$t('Custom price applied')))]),_c('orderable-action-product-reset-price',{attrs:{"product-id":item.id,"icon":"","small":"","icon-name":"restart_alt","label":_vm.$t('Reset product\'s price and VAT')},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}})],1):_vm._e()]},proxy:true}],null,true)}),(!_vm.readonly)?_c('div',{staticClass:"mx-1"},[_c('orderable-update-product-dialog',{attrs:{"cart-item":item,"customer":_vm.value.customer},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}})],1):_vm._e()],1)]},proxy:true},{key:"expand-icon",fn:function(ref){
var expanded = ref.expanded;
return [_c(VIcon,{staticClass:"mx-4"},[_vm._v(" "+_vm._s(expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down')+" ")])]}},{key:"content",fn:function(){return [_c(VDivider,{staticClass:"mx-6"}),_c('orderable-preview-item-taxes',{staticClass:"pa-4",attrs:{"value":item}}),((item.components || []).length)?[_c(VDivider,{staticClass:"mx-6"}),_c('orderable-preview-item-components',{staticClass:"px-4",attrs:{"value":item}})]:_vm._e()]},proxy:true}],null,true)}),_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[(item.notes && ("" + (item.notes)))?_c('div',{staticClass:"d-flex flex-column flex-gap-16 mx-4"},[_c(VDivider,{staticClass:"mx-2"}),_c('div',{staticClass:"px-3 d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('Note'))+":")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(("" + (item.notes))))])])],1):_vm._e(),((item.extras || []).length)?_c('div',{staticClass:"d-flex flex-column flex-gap-16 mx-4"},[_c(VDivider,{staticClass:"mx-2"}),_c('div',{staticClass:"px-3 d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('Extras'))+":")]),_c('orderable-preview-item-extras',{staticClass:"grey--text",attrs:{"value":item}})],1)],1):_vm._e()])],1)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }