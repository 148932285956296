import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"1200","scrollable":""},on:{"input":_vm.onOpenChange},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.addToCart.apply(null, arguments)}}},[_c(VCard,{attrs:{"height":(_vm.cardHeight + "px")}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.product.name)+" "+_vm._s((_vm.cardHeight + "px")))]),_c('div',{staticClass:"d-flex"},[_c(VIcon,{attrs:{"size":"32"},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("close")])],1)]),_c(VDivider),_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(_vm.loadingProductDetails)?_c(VCardText,{staticClass:"d-flex justify-center align-center"},[_c(VProgressCircular,{attrs:{"size":"50","color":"primary","indeterminate":""}})],1):(_vm.productDetails)?_c(VCardText,{staticClass:"d-flex justify-space-between pa-0 ma-0"},[_c('orderable-add-product-sections',{attrs:{"section":_vm.currentSection,"customer":_vm.value.customer,"vat":_vm.vat,"product":_vm.productDetails,"height":_vm.cardHeight,"errors":_vm.errors},on:{"update:section":function($event){_vm.currentSection=$event},"update:vat":function($event){_vm.vat=$event},"input":_vm.onItemChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1):_vm._e()],1),_c(VDivider),(_vm.productDetails)?_c(VCardActions,{staticClass:"d-flex justify-end pa-3"},[_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":!_vm.isValid,"type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Add to cart'))+" ( "),_c('money-preview',{attrs:{"value":_vm.finalPrice,"multiplier":_vm.item.quantity}}),_vm._v(" ) ")],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }