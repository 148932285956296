import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errors['items'])?_c('span',{staticClass:"error--text text-caption"},[_vm._v(_vm._s(_vm.$t(_vm.errors['items'])))]):_vm._e(),_c('selectable-products',{attrs:{"grid-options":_vm.productOptions,"show-select":false},scopedSlots:_vm._u([{key:"item.real_price",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.real_price}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canAdd(item))?_c(VBtn,{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.onItemSelect(item)}}},[_c(VIcon,[_vm._v("shopping_cart")])],1):_vm._e()]}}])}),(_vm.selectedItem)?_c('orderable-add-product-dialog',{attrs:{"product":_vm.selectedItem,"open":_vm.isOpen},on:{"update:open":function($event){_vm.selectedItem = undefined}},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }